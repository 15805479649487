@import 'css/shared';

.modal {
  .modal-header {
    padding-bottom: 20px;
    border-bottom: 0 !important;
    .modal-title {
      font-weight: bold;
      color: $blue;
      font-family: $primary-font-family !important;
      font-size: $h3-font-size !important
    }
  }
  .modal-body {
    border-bottom: 0 !important;
  }
  .modal-footer {
    border-top: 0 !important;
  }
}

.modal.modal-newsletter-form {
  .modal-header {
    justify-content: center; 
    .modal-title  {
      text-align: center;
    }
  }
  .modal-body {
    text-align: center !important;
  }
  .modal-footer {
    justify-content: center; 
  }
  .Icon.icon-delete {
    display: none;
  }
}

