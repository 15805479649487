@import 'css/shared';

.best-price {


    .text-promo {
        color: $secondary !important;
        font-weight: 700;
    }

}

.best-price-modal {

    .price-container {
        
        .price {
            font-weight: 700;
        }

    }

    .text-promo {
        font-weight: 700;
    }


}

.product-tile .best-price {
    // display: flex;

    button svg {
        font-size: 12px !important;
        width: 12px !important;
    }    
        
}

.product-page .best-price {
    display: flex;

    button svg {
        font-size: 12px !important;
        width: 12px !important;
    }
    
}