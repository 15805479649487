@import '../shared';

.cart-sku {
  .product-name {
    font-weight: 700;
    margin-bottom: map-get($spacers, 2) !important;
    font-size: $h4-font-size;
  }
  .text-offer {
    color: $promo;
  }
  .cart-sku-badges {
    .badge {
      .children-wrapper {
        @extend .h4;
      }
    }
    .free-shipping {
      @extend .bg-secondary, .bg-opacity-10, .text-secondary;
    }
    .discount {
      @extend .bg-red, .bg-opacity-10, .text-red;
    }
  }
  .assembled-component-product-list {
    list-style: disc !important;
    @extend .ps-3;
  }
}
