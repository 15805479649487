@import '../css/shared';

.header-banner-page {
  .Breadcrumb {
    .breadcrumb-item {
      color: $white;
      a {
        color: $white;
      }
      & + .breadcrumb-item:before {
        border-color: $white;
      }
    }
  }

  .container-bg {
    padding: 80px 0;
    @include media-breakpoint-up(lg) {
      padding: 100px 0;
    }
  }

  .text-block-coverer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .text-block-container {
      height: 100%;
      position: relative;
      .text-block-resetter {
        height: 100%;
        .text-block {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
          color: $white;
          &.start {
            text-align: left;
          }
          &.center {
            text-align: center;
            align-items: center;
          }
          &.end {
            align-items: flex-end;
            text-align: right;
          }
          @include media-breakpoint-down(md) {
            &.mobile-bottom {
              justify-content: flex-end;
            }
            &.mobile-center {
              justify-content: center;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .text-block-coverer {
      .text-block-container {
        .text-block-resetter {
          .text-block {
            justify-content: center;
            align-items: flex-start;
          }
        }
      }
    }
  }
}
