@import 'css/shared';

.product-tile {
  //.product-tile-basic-info {}
  .product-tile-image-container {
    padding: 20px;
  }
  .product-title {
    @include media-breakpoint-down(xs) {
      font-size: $small-font-size;
    }
  }
  .product-tile-extra-info {
    .add-to-cart-button {
      //width: 35px;

      @include media-breakpoint-down(lg) {
        //width: 25px;
      }
    }
  }

  /*// -- Inizio implementazione immagine secondaria -- //*/

  .alternative-image {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
  }

  @include media-breakpoint-up(lg) {
    .product-tile-image-container {
      overflow: hidden;

      .alternative-image {
        display: block;
        opacity: 0;
        transition: opacity 0.4s;
      }
    }

    & :hover {
      .alternative-image {
        opacity: 1;
      }
    }
  }

  /*// -- Fine implementazione immagine secondaria -- //*/

  .product-variant {
    .product-variant-container {
      & > div {
        margin: 0 !important;
      }
    }
  }

  @include media-breakpoint-up(lg) {
  }
  @include media-breakpoint-down(lg) {
  }
}
