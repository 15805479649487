@import '~bootstrap/scss/functions';
@import 'variables';

// RIDEFINISCO ALCUNE COSE SENZA MODIFICARLE, GIUSTO PER AVERLE DIPONIBILI >>>>>

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

// BOOTSTRAP OVERRIDES BEFORE IMPORT >>>>>

$enable-negative-margins: true;

$custom-container-max-widths: (
  xxl: 1434px,
);

$primary-font-family: 'Montserrat', sans-serif;
$secondary-font-family: 'Zilla Slab', sans-serif;

$font-family-sans-serif: $primary-font-family;
$font-size-base: 1rem; // 16px`

$headings-font-family: $secondary-font-family;
$headings-font-weight: 400;

$line-height-base: 1.5;

$display-font-sizes: (
  1: $font-size-base * 3.125,
  2: $font-size-base * 3.5,
  3: $font-size-base * 3.25,
  4: $font-size-base * 3,
  5: $font-size-base * 2.75,
  6: $font-size-base * 2.5,
);

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.625;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.125;
$h5-font-size: $font-size-base * 1;

$small-font-size: $font-size-base * 0.875;
$extra-small-font-size: $font-size-base * 0.75;

$black: #000000;
$white: #ffffff;
$gray-100: #f6f6f6;
$gray-200: #e6e6e6;
$gray-300: #b4b4b4;
$gray-400: #5d5d5d;
$promo: #a71930;
$yellow: #ffd300;
$blue: #003a6b;
$light-blue: #0173b4;
$lighter-blue: #dbedfc;
$green: #607b26;
$light-green: #8ba949;
$brown: #b06100;
$purple: #6f2546;
$red: #a71930;
$pink: #ff2954;

$custom-colors: (
  'black': $black,
  'white': $white,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'promo': $promo,
  'yellow': $yellow,
  'blue': $blue,
  'light-blue': $light-blue,
  'light-green': $light-green,
  'green': $green,
  'brown': $brown,
  'red': $red,
  'purple': $purple,
);

$border-radius: 10px;
$border-radius-sm: 5px;
$border-radius-lg: 40px;

$body-bg: $white;
$body-color: $blue;

$primary: $yellow;
$secondary: $blue;
$border-color: $gray-200;
$table-group-separator-color: $border-color;
$table-bg-scale: -10%;
$table-variants: (
  'light': $gray-100,
);

$input-btn-line-height: 1.5;
$input-btn-padding-y-sm: 0.3rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
$input-btn-font-size-sm: $extra-small-font-size !default;

$btn-font-size: 1rem;
$btn-font-weight: 700;
$btn-border-radius: 20px;
$btn-border-radius-sm: 20px;
$btn-border-radius-lg: 20px;
$btn-padding-x: 1.5rem;
$btn-padding-y: 0.4375rem;
$input-padding-y: 0.4375rem;

$input-border-color: $gray-300;
$input-placeholder-color: $gray-300;

$input-border-radius: 5px;
$form-check-input-border-radius: $input-border-radius;
$form-select-border-radius: $input-border-radius;

$form-label-font-weight: 400;
$form-label-font-size: $small-font-size;

$form-check-input-width: 1.125em;
//$form-check-input-border: 1px solid $secondary;
$form-check-input-checked-border-color: $gray-300;
$form-check-input-checked-bg-color: $white;
$form-check-input-checked-color: $primary;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-check-input-checked-color}'/></svg>");
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg viewBox='-2 -2 14 14' xmlns='http://www.w3.org/2000/svg'><rect width='10' height='10' fill='#{$form-check-input-checked-color}'/></svg>");

$offcanvas-horizontal-width: 500px;
$offcanvas-padding-x: 3rem;
$offcanvas-border-width: 0;

$accordion-border-radius: 0;
$accordion-button-active-color: $secondary;
$accordion-button-active-bg: transparent;
$accordion-button-focus-border-color: transparent;
$accordion-button-focus-box-shadow: transparent;

$alert-border-radius: $border-radius;

$badge-border-radius: 0.25rem;

// BOOTSTRAP OVERRIDES BEFORE IMPORT <<<<<

@import '../../node_modules/bootstrap/scss/variables';
//@import '/node_modules/bootstrap/scss/utilities';

$container-max-widths: map-merge($container-max-widths, $custom-container-max-widths);
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-text: map-merge($utilities-text, $custom-colors);
$utilities-text-colors: map-loop($utilities-text, rgba-css-var, '$key', 'text');
$utilities-bg: map-merge($utilities-bg, $custom-colors);
$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, '$key', 'bg');
