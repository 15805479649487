@import '../css/shared';

.newsletter-subscribe-page {

  .form-check-label {
    a {
      color: $white !important;
    }
  }

  .newsletter {
    border-radius: $border-radius-lg;
  }
  
  .left-box {
    @include media-breakpoint-up(lg) {
      width: 50% !important;
    }
  }

  .right-box {
    background-color: $light-blue;
    @include media-breakpoint-up(lg) {
      width: 50% !important;
    }
  }
  

}
