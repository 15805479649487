@import '../css/shared';

.account-page {

  .PageWithSubpageHeader {

    .title {
      font-size: $h1-font-size;
      font-weight: 700;
      color: $secondary;
    }
    
    .subpage-title {
      color: $secondary;
      font-size: $h4-font-size;
      text-transform: uppercase;
      font-family: $primary-font-family;
    }

    .subpage-title-container{
      border: 0 !important;
    }

  }

  .box-content {
    box-shadow: none !important;
    a {
      color: $secondary !important;
    }
  }
  
 
  .inner-box {

    .inner-box-title {
      color: $secondary;
    }
   
    .cta-title {
      font-weight: 400;
      color: $gray-400;
      font-size: $extra-small-font-size;
    }

  }

  .inner-address-box {
    .btn {font-weight: 400;}
  }

  .menu-account {

    .btn {
      font-weight: normal;
      text-transform: initial;
    }

    .list-group {

      .list-group-item {
        border-radius: 0;
        margin-bottom: spacer(1) !important;
        color: $secondary !important;
        font-weight: 600;

        &.active {
          background-color: $primary !important;
        }

        .Icon {
          font-size: 26px !important;
          color: $secondary;
        }

      }

    }

  }

  .order-tracking-url {
    font-weight: 700;
  }

}