@import 'bootstrap-customization';
@import '~bootstrap/scss/bootstrap.scss';
@import 'mixins';

// OTHER CUSTOMIZATIONS AFTER IMPORT >>>>>

:root {
  @each $bp, $value in $grid-breakpoints {
    --breakpoint-#{$bp}: #{$value};
  }
}

@include generate-width-classes($grid-breakpoints);

.btn-white {
  color: $blue;
}

.btn-transparent {
  @include button-variant(transparent, $white, $blue, $white, $white, $blue);
}

// // Rendo responsive le classi di utilità dedicate ai position
// $positions: (fixed, absolute, relative, sticky, static);
// @each $breakpoint in map-keys($grid-breakpoints) {
//   @include media-breakpoint-up($breakpoint) {
//     $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
//     @each $position in $positions {
//       .position#{$infix}-#{$position} {
//         position: $position !important;
//       }
//     }
//   }
// }
