@import 'css/shared';

.cart-offcanvas {
  .offcanvas-title {
    span {
      color: $secondary !important
    }
  }
  .cart-packs {

    .cart-pack {
      
      & > .cart-sku:first-child > div {padding-top: 0 !important;}
      & > .cart-sku:last-child > div  {padding-bottom: 0 !important;}
      
      .SeparatedList {
        .btn {
          font-weight: 400;
          color: $gray-400;
        }
      }

      @include media-breakpoint-up(lg) {
        .cart-sku-action {
          padding: map-get($spacers, 3) 0 !important;
    
          .cart-sku-price {font-size: $h6-font-size;}
          .text-offer {
            color: $promo;
          }
    
          & > div:first-child {
    
            & > .row > .col {
              width: 100%;
              display: flex;
              justify-content: flex-end;
    
              &:first-child {
                margin-bottom: 10px;
              }
    
            }
            
          }
    
        }
      
      }
    
    }

  }

  .footer-message {
    text-align: center;
    color: $secondary;
    font-size: $small-font-size;
  }

  .progress {
    .progress-bar {
      background: $red !important;
  
      &[aria-valuenow='100'] {
        background: $light-green !important;
      }
    }
  }

}
