@import 'css/shared';

.ListA {
    
  color: $blue !important;
  .vr {
    height: 12px;
    min-height: 0;
    color: $blue;
  }

  .text-initial {
    text-transform: initial;
  }
}
