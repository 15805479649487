@import '../css/shared';

.landing-template-b {
  .slider-section {
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      .swiper-pagination {
        display: none;
      }
    }

    @include media-breakpoint-down(lg) {
      .swiper {
        overflow: visible;
      }
      .swiper-pagination {
        position: relative;
        margin-top: map-get($spacers, 3);
      }
    }
  }
}
