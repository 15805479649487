@import 'css/shared';

.box-e {
  .title {
    font-family: $secondary-font-family;
  }

  .copy-container {
    text-align: center;
  }

  @include media-breakpoint-up(lg) {
    .title {
      font-size: $h2-font-size;
    }

    .content {
      font-size: $h4-font-size;
    }

    .label-with-icon {
      font-size: $h4-font-size;
    }

    .copy-container {
      background-color: transparent !important;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  @include media-breakpoint-down(lg) {
    .title {
      font-size: $h3-font-size;
    }

    .content {
      font-size: $small-font-size;
    }

    .label-with-icon {
      font-size: $small-font-size;
    }
  }
}
