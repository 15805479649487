@import 'functions';
@import 'bootstrap';
@import 'swiper';
@import 'mixins';
@import 'fonts';
@import 'b2x-components';
@import 'b2x-theme/b2x-theme';
// Decommentare questo stile per debuggare i CSS.
// * {
//   background-color: rgba(green, 0.05);
// }

body {
  overflow-y: scroll;
}

.btn {
  text-transform: uppercase;
  text-wrap: nowrap;
  &.no-uppercase,
  &.btn-link,
  &.btn-blank {
    text-transform: none;
  }
}
.btn-primary,
.btn-outline-primary,
.btn-white,
.btn-gray-200,
.btn-outline-gray-200,
.btn-link {
  color: $secondary !important;
}

.text-initial {
  text-transform: initial !important;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: inherit !important;
}

.form-check-label {
  a {
    color: $secondary;
  }
}

.newsletter-section {
  .form-check-label {
    a {
      color: $white;
    }
  }
}

.table-light {
  color: $secondary !important;
}
