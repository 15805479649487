@font-face {
  font-family: 'Zilla Slab';
  src: url('../fonts/ZillaSlab/ZillaSlab-SemiBold.eot');
  src: url('../fonts/ZillaSlab/ZillaSlab-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/ZillaSlab/ZillaSlab-SemiBold.woff2') format('woff2'),
      url('../fonts/ZillaSlab/ZillaSlab-SemiBold.woff') format('woff'),
      url('../fonts/ZillaSlab/ZillaSlab-SemiBold.svg#ZillaSlab-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Zilla Slab';
  src: url('../fonts/ZillaSlab/ZillaSlab-Bold.eot');
  src: url('../fonts/ZillaSlab/ZillaSlab-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/ZillaSlab/ZillaSlab-Bold.woff2') format('woff2'),
      url('../fonts/ZillaSlab/ZillaSlab-Bold.woff') format('woff'),
      url('../fonts/ZillaSlab/ZillaSlab-Bold.svg#ZillaSlab-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Light.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.primary-font {
  font-family: $primary-font-family !important;
}
.secondary-font {
  font-family: $secondary-font-family !important;
}

.display-1,
.display-2,
.display-1,
.display-4,
.display-5,
.display-6 {
  font-family: $secondary-font-family;
}

.fw-medium {
  font-weight: 600;
}

.extra-small {
  font-size: $extra-small-font-size;
}

.fw-semi-bold {
  font-weight: 600 !important;
}
