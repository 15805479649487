@import '../css/shared';

.popup-newsletter-modal {
  .modal-content {
    overflow: hidden;
    
    .modal-header {

      .Icon.icon-delete {
        color: $white;
      }
    }
  }
}