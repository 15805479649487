@import 'css/shared';

$fade-duraion: 200ms;

.mobile-header {
  background-color: $black;
  transition: background-color $fade-duraion;
  &.transparent {
    background-color: transparent;
    border-bottom-color: transparent !important;
  }

  .btn .badge {
    font-size: 10px;
    padding: 3px 5px;
  }
  
}

.offcanvas-top {

  .offcanvas-header {
    border: 0 !important;
  }

  .simple-search-form {

      input {
          border-radius: 0;
          padding: map-get($spacers, 2) 0;
          box-shadow: none;
          font-size: $h3-font-size;
      }

      button {
        padding-right: 0 !important;
      }

      .Icon {
        font-size: $h3-font-size;
      }

  }
  
}