@import 'css/shared';

.custom-sku-radio {
  cursor: pointer;
  display: inline-block;
  padding: 0 map-get( $spacers, 2 );
  border: 1px solid $gray-200;
  background: $gray-200;

  text-transform: lowercase;
  font-size: $small-font-size;
  font-weight: 700;

  position: relative;
  overflow: hidden;

  &.sku-not-available {
    opacity: 0.5;

      &:before {
        content: '';
        width: 150%;
        height: 1px;
        background: $secondary;
        position: absolute;
        left: -25%;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: rotate(45deg);
      }

  }

  &.selected {
    border-color: $secondary;
    background: $lighter-blue;
  }

}

input[type='radio']:checked + label .custom-sku-radio {
  border-color: $secondary;
  background: $lighter-blue;
}