@import 'css/bootstrap';

.nutritional-table-container {
  max-width: 500px;
  line-height: $line-height-sm;

  table {
    @extend .table;
    @extend .table-hover;
  }

  th, td {
    font-weight: 600;

    small { font-weight: $font-weight-normal; }

  }

  th {
    text-transform: uppercase;
    color: $light-blue;

    small { color: $blue; }

  }

  td {
    text-align: right;
    color: $blue;
  }

}
