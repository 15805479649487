@import 'css/shared';

.section-landing {

    .asset-cta {
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
    }


}