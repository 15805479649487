@import '../shared';

.product-assembler {
  .steps {
    .step {
      .circle-wrapper {
        &:before,
        &:after {
          background-color: tint-color($primary, 50);
        }
        .circle {
          @extend .fw-semi-bold, .border-primary, .bg-primary;
        }
      }
      .text {
        @extend .h4, .primary-font;
      }
      &.ahead {
        .circle {
          border-color: tint-color($primary, 50) !important;
          color: tint-color($body-color, 50);
        }
        .text {
          color: tint-color($body-color, 50);
        }
      }
    }
  }
  .assembled-product-info-wrapper {
    @extend .shadow, .rounded-2, .bg-white;
    top: $desktop-sticky-elements-top;
    z-index: 1;
    .assembled-product-info {
      .name {
        @extend .h2, .fw-bold, .text-uppercase;
      }
    }
  }
  .discount-badge {
    @extend .bg-red, .bg-opacity-10, .text-red;
  }
  .free-shipping-badge {
    @extend .bg-secondary, .bg-opacity-10, .text-secondary;
  }

  .desktop-summary {
    @extend .shadow, .rounded-2;
    top: $desktop-sticky-elements-top;
    // max-height: $desktop-sticky-elements-max-height;

    .summary-title {
      @extend .h2, .fw-bold, .text-uppercase, .text-center, .mb-0;
    }

    .badges {
      .badge {
        text-align: left;
      }
    }

    .accordion {
      .accordion-header {
        background-color: $gray-100;

        button {
          background: transparent;
          padding: map-get($spacers, 2);

          &[disabled] {
            opacity: 0.5;
          }

          .counter {
            font-weight: 400;
            font-size: $small-font-size;
          }
        }
      }

      .accordion-body {
        padding: map-get($spacers, 3) 0;

        .summary-items-info-message {
          padding: 0 map-get($spacers, 2);
          font-size: $small-font-size;
        }

        padding: map-get($spacers, 3) 0;

        .summary-items-info-message {
          padding: 0 map-get($spacers, 2);
          font-size: $small-font-size;
        }

        .summary-item {
          padding: 0 map-get($spacers, 2);

          & + .summary-item {
            padding-top: map-get($spacers, 3);
            border-top: 1px solid $gray-200;
          }

          .summary-item-name {
            font-weight: 700;
          }

          .summary-item-name,
          .summary-item-sku-name {
            font-size: $small-font-size;
          }

          .summary-item-quantity {
            .d-flex {
              justify-content: end;
            }

            button,
            input {
              width: 35px !important;
              height: 35px;
            }
          }

          .special-price {
            color: $promo;
          }

          .summary-item-remove {
            font-weight: 400;
            color: $gray-400;
            text-decoration: underline;
          }
        }
      }
    }

    .summary-total {
      font-weight: 700;
    }

    .summary-action {
      .prev-button-container,
      .next-button-container {
        display: grid;
      }

      .prev-button-container {
        button {
          background: transparent;
        }
      }
      .summary-action-info-message {
        font-size: $extra-small-font-size;
      }
    }
  }

  .mobile-summary {
    .navigation-button-container {
      .prev-button-container,
      .next-button-container {
        @extend .d-grid;
      }

      .prev-button-container {
        button {
          background: transparent;
        }
      }
    }

    .summary-main-action {
      margin-top: map-get($spacers, 5);
      background: $white;
      @extend .shadow, .rounded-top;

      .toggle-offcanvas-button-container,
      .navigation-button-container {
        @extend .d-grid;
      }

      .toggle-offcanvas-button-container {
        .btn {
          background: $white;
          border-color: $white;
          padding: map-get($spacers, 2) map-get($spacers, 3);
          .icon,
          .counter {
            width: 50px;
            font-size: $small-font-size;
          }

          .label {
            @extend .lh-1, .fs-3;
            font-family: $secondary-font-family;
          }
        }
        .component-info {
          @extend .small, .secondary-font;
          text-transform: none !important;
        }
      }
    }
  }

  .progress {
    height: 4px !important;
    border-radius: 0 !important;
    background: transparent !important;

    .progress-bar {
      background: $red !important;

      &[aria-valuenow='100'] {
        background: $light-green !important;
      }
    }
  }

  .summary-offcanvas {
    .summary-container {
      @extend .shadow, .rounded-top;
      .summary-inner-container {
        @extend .rounded-top;

        .summary-header {
          @extend .d-grid;

          .btn {
            background: $white;
            border-color: $white;
            padding: map-get($spacers, 2) map-get($spacers, 3);
            .icon,
            .counter {
              width: 50px;
              font-size: $small-font-size;
            }

            .label {
              @extend .lh-1, .fs-3;
              font-family: $secondary-font-family;
            }
          }
        }

        .badges {
          .badge {
            text-align: left;
          }
        }

        .accordion {
          .accordion-header {
            background-color: $gray-100;

            button {
              background: transparent;
              padding: map-get($spacers, 2);
              font-size: $small-font-size;

              &[disabled] {
                opacity: 0.5;
              }

              .counter {
                font-weight: 400;
                font-size: $small-font-size;
              }
            }
          }

          .accordion-body {
            padding: map-get($spacers, 3) 0;

            .summary-items-info-message {
              padding: 0 map-get($spacers, 2);
              font-size: $small-font-size;
            }

            .summary-item {
              padding: 0 map-get($spacers, 2);

              & + .summary-item {
                padding-top: map-get($spacers, 3);
                border-top: 1px solid $gray-200;
              }

              .summary-item-name {
                font-weight: 700;
              }

              .summary-item-name,
              .summary-item-sku-name {
                font-size: $small-font-size;
              }

              .summary-item-quantity {
                .d-flex {
                  justify-content: end;
                }

                button,
                input {
                  width: 35px !important;
                  height: 35px;
                }
              }

              .special-price {
                color: $promo;
              }

              .summary-item-remove {
                font-weight: 400;
                color: $gray-400;
                text-decoration: underline;
              }
            }
          }
        }

        .summary-total {
          font-weight: 700;
        }

        .summary-action {
          .prev-button-container,
          .next-button-container {
            display: grid;
          }
          .prev-button-container {
            button {
              background: transparent;
            }
          }
          .summary-action-info-message {
            font-size: $extra-small-font-size;
          }
        }
      }
    }
  }
}
