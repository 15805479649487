@import 'css/shared';

.box-c {
  .swiper {
    .swiper-pagination {
      @include media-breakpoint-up(lg) {
        text-align: left;
        margin-left: spacer(5);
        padding-left: spacer(5);
      }
      .swiper-pagination-bullet {
        background-color: $white;
        &.swiper-pagination-bullet-active {
          background-color: $primary;
        }
      }
    }
  }
}
