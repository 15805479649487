@import '../css/shared';

.account-home-page {

  .btn {
    font-weight: 400 !important;
  }

  .PageWithSubpageHeader {

      .title {
        font-size: $h1-font-size;
        font-weight: 700;
      }
      
    }

  .box-home-account {
    h6 {
      text-transform: uppercase;
    }
  }

  .box-home-account, .help {

    a {
      color: $secondary !important;
    }
   
    h6, svg {
      color: $secondary !important;
      font-family: $primary-font-family;
    }
  }  
  
  .Icon, .box-title, .title {
    color: $secondary;
  }

}