@import 'css/shared';

.mobile-search-filter-offcanvas {
  .offcanvas-header-wrapper {
    .offcanvas-header {
      border: 0 !important;
    }
    .offcanvas-title {
      text-transform: none !important;
    }
  }
  .btn {
    border-radius: 0;
  }

}