@import '../shared';

.circle-progress-bar {
  .background {
    stroke: $gray-200;
  }
  .bar {
    stroke: $red;
  }
  &.completed {
    .bar {
      stroke: $green;
    }
  }
  .current-value {
    @extend .fw-bold;
    font-size: 40px;
  }
}
