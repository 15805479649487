@import 'css/shared';

.Accordion {
  .accordion-header button {
    font-family: $primary-font-family;
    font-weight: 700 !important;
  }

  .accordion-body:empty {
    padding: 0;
  }

  .row > [class^='col-'] {
    .accordion-item {
      border-bottom: 0;
    }
    &:last-child {
      .accordion-item {
        border-bottom: 1px solid $gray-200;
      }
    }
  }

  .accordion-item {
    border-left: 0;
    border-right: 0;
  }

  .accordion-button {
    &:not(.collapsed) {
      box-shadow: none !important;
    }
  }

  @include media-breakpoint-up(lg) {
  }
  @include media-breakpoint-down(lg) {
  }
}
