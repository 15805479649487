@import 'css/shared';
.banner-a {
  .col {
    z-index: 1;
  }
  .lottie-item {
    z-index: 8;
    min-height: 700px;
  }
  .banner-a-copy {
    z-index: 10;
  }
}
