@import '../css/shared';

.product-page {
  .ringbell {
    width: 100% !important;

    svg {
      width: 50px !important;
      height: 50px !important;
    }
  }

  .product-gallery {
    .gallery-thumbnails,
    .gallery-slides {
      .swiper-slide {
        background: $gray-100;
      }
    }

    .gallery-thumbnails {
      .swiper {
        margin: -(map-get($spacers, 2));
        padding: map-get($spacers, 2);
      }

      .swiper-slide {
        border: 1px solid transparent;

        &.border {
          border-color: $light-blue !important;
          box-shadow: 0 0 6px 1px rgba(var(--bs-light-blue-rgb), 0.4);
        }
      }
    }

    .swiper-pagination-bullet {
      opacity: 1;
      background: $gray-200;

      &.swiper-pagination-bullet-active {
        background: $primary;
      }
    }

    .gallery-slides {
      width: 100%;
    }
  }

  .quantity-container {
    .btn-container {
      width: 40px;
      display: grid;
    }

    input {
      width: 100%;
    }

    input[type='number'] {
      -moz-appearance: textfield;
      appearance: textfield;
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      appearance: none;
    }
  }

  .product-third-section {
    overflow: hidden;
  }

  @include media-breakpoint-up(lg) {
    .pasta-info-icon {
      max-width: 150px;
    }

    .product-gallery {
      .gallery-thumbnails {
        width: 50px;
      }

      .gallery-slides {
        max-width: calc(100% - 50px);
      }

      .swiper-pagination {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .pasta-info-icon {
      max-width: 125px;
    }

    #product-accordion {
      .accordion-header button,
      .accordion-body {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .product-first-section {
      .container-xxl,
      .container-xxl .row {
        --bs-gutter-x: 0;
      }
    }

    .product-third-section {
      overflow: hidden;

      .swiper {
        width: 100%;
        overflow: visible;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .product-first-section {
      .AddToCartForm {
        .add-to-cart-button-container {
          display: grid;
          width: 100%;
        }
      }
    }
  }

  .product-promo-code {
    border-radius: 10px;

    @include media-breakpoint-down(md) {
      .button-col {
        text-align: center;
      }
    }
  }
}

.modal.modal-copy-coupon {
  .modal-header {
    justify-content: center;
    padding-bottom: 0 !important;
    .modal-title {
      font-size: $h3-font-size !important;
    }
  }
  .modal-body {
    text-align: center !important;
  }
}
