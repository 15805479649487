@import '../css/shared';

.customer-form-b {
  .btn {
    width: 100%;
  }
  .box-newsletter-radio {
    background-color: $light-blue;
    border-radius: $border-radius-lg;
    padding-left: 30px;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-right: 30px;
    text-align: center;
    color: $white;
    .title {
      font-weight: 700;
      font-size: $h4-font-size;
    }
    .form-check-label {
      text-align: left;
      a {
        color: $white;
      }
    }
  }
}

.form-group .form-label.required:after {
  color: $red;
  content: '*';
  margin-left: 0.25rem;
}

.Breadcrumb {
  ol {
    margin: 0;
  }

  .breadcrumb-item {
    font-size: $font-size-base * 0.75;

    a {
      color: $secondary;
      text-decoration: none;
    }

    & + .breadcrumb-item:before {
      content: '';
      float: none;
      padding: 0;
      display: inline-block;
      width: 6px;
      height: 6px;
      top: -1px;
      position: relative;
      margin-right: 11px !important;
      border-right: 1px solid $gray-400;
      border-bottom: 1px solid $gray-400;
      transform: rotate(-45deg);
    }

    &.active {
      font-weight: 300;
    }
  }
}
