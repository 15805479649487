@import 'css/shared';

.mobile-navigation-offcanvas {

    .Drawers {
        
        .Drawer {

            .border-bottom {
                border-radius: 0;
                border-color: $gray-200 !important;
            }

        }

    }


    .mobile-navigation-offcanvas-footer {

        .drawer-locale-container {
            
            & > .d-grid > .btn {
                border: 0 !important;
            }

        }

    }

    .LocaleForm {
        & > div:first-child {
            text-transform: none !important;
            font-weight: 600;
        }
    }

}