@import 'css/shared';

.footer {
  position: relative;
  color: $blue !important;
  border-top: 2px solid $gray-200;

  .accordion {
    .accordion-item {
      border-bottom: 0 !important;
      .accordion-header button {
        font-family: $primary-font-family !important;
        font-weight: 500 !important;
        text-transform: uppercase;
        font-size: $font-size-base !important;
        margin-bottom: map-get($spacers, 2);
      }
    }
  }

  .footer-hr {
    color: $gray-200;
    height: 2px;
  }

  @include media-breakpoint-down(lg) {

    .container-xxl,
    .container-xxl .row {
      --bs-gutter-x: 0;
    }
    
  }
 
}