@import '../css/shared';

.search-page {
  
  .order-dropdown {
    button:after {display: none;}

    .dropdown-item.active, .dropdown-item:active { 
      color: $secondary;
      background-color: $white;
      font-weight: 700;
    }

    ul {
      top: -2px !important;
      padding: 0;
      border: 0;
      width: 100%;
    }
  }

  .pagination {
    
    .page-item {

      @include media-breakpoint-up(lg) {
        padding: 0 map-get($spacers, 2);
      }

      .page-link {
        border: 0;
        color: $secondary;
        background: transparent;
        border-radius: 0;
        padding: map-get($spacers, 2);
      }

      &.active {
        font-weight: 700;
        .page-link {
          border-bottom: 3px solid $secondary;
        }
      }

    }

  }

  .search-filters-container {
    position: sticky;

    overflow-y: scroll;
    overflow-x: hidden;

    .search-filters > ul {
      
      display: flex;
      flex-direction: column;
      
      .search-result {display: none;}
      
    }

  }

  
  .search-filters {
    button {
        color: $secondary !important;
    }
    .main-category {
      .btn,
      .form-check-label {
        font-size: $font-size-sm;
        text-transform: uppercase;
        font-weight: 600;
        color: $secondary !important;
      }
    }
    .form-check-input {
        border-color: $secondary;
      }
    
    .form-check-label {
        color: $secondary
    }
    .accordion-header {
      .form-check-label {
        font-weight: 600;
      }
    }
    .filter-type-facet {
      > div {
        color: $secondary !important;
        font-weight: 600 !important;   
        text-transform: uppercase;     
      }
    }
  }

// // Scrollbar customization - Start

    ::-webkit-scrollbar-track-piece:start {
        margin-top: 3rem;
    }

    ::-webkit-scrollbar-track-piece:end {
        margin-bottom: 3rem; 
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track-piece {
        background: $gray-200;
        border-radius: $border-radius;
    }

    ::-webkit-scrollbar-thumb {
        background: $gray-300;
        border-radius: $border-radius;
    }

    // // Scrollbar customization - End

  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-down(lg) {}

}