@import 'css/shared';

.account-offcanvas {

  .offcanvas-title,
  .offcanvas-body-title {
    font-size: $h2-font-size;
    font-weight: 700;
  }

  .offcanvas-body-registration-wrapper {
    position: relative;
    background: $light-blue !important;
    color: $white;

    @include media-breakpoint-up(md) {

      &:after {
        content: '';
        position: absolute;
        width: 180px;
        height: 140px;
        background: url('./images/spiga-login.png') no-repeat scroll center center transparent;
        top: -58px;
        right: 0;
      }

    }

  }

  .offcanvas-body {

    & > div:first-child {
      padding-top: 1.5rem !important;
    }

  }

}