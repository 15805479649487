@import '../css/shared';

#CookiebotWidget {
  z-index: 1000 !important;
}

.assembled-product-page {
  .icon-added-container {
    border: 0.25rem solid $gray-300;
    width: 45px;
    height: 45px;
  }

  .assembled-product-summary {
    // top: 3rem;
    overflow-y: scroll;
    padding-right: map-get($spacers, 4);
  }

  .summary-item {
    .price-block .d-flex {
      justify-content: flex-end;

      .crossed-out-price {
        margin-right: map-get($spacers, 2);
      }
    }
  }

  // // Scrollbar customization - Start

  ::-webkit-scrollbar-track-piece:start {
    margin-top: 2.75rem;
  }

  ::-webkit-scrollbar-track-piece:end {
    margin-bottom: 3rem;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track-piece {
    background: $gray-200;
    border-radius: $border-radius;
  }

  ::-webkit-scrollbar-thumb {
    background: $gray-400;
    border-radius: $border-radius;
  }

  // // Scrollbar customization - End

  @include media-breakpoint-up(lg) {
    .filters-sticky {
      top: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    .filters-sticky {
      top: 58px;
    }
  }
}

.assembled-product-summary-offcanvas {
  height: 100vh;

  .crossed-out-price {
    margin-right: map-get($spacers, 2);
  }
}
