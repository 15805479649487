@import 'css/shared';

.desktop-header {
    .desktop-header-menu {
        position: relative;
        z-index: 10;
    }

    .menu-item-first-level {
        border-color: transparent !important;
        transition-delay: 0.3s;
        transition: border-color 0.3s;
        cursor: pointer;
    }

    .menu-item:hover,
    .menu-item.active {
        .menu-item-first-level {
            border-color: $light-blue !important;
        }
    }

    .sub-menu-dropdown {
        position: absolute;
        background: $white;
        z-index: 5;
        opacity: 0;
        transform: translateY(-15px);
        transition: opacity 0.8s ease, transform 0.5s ease;
        box-shadow: 0px 5px 10px -10px rgba(0,0,0,0.4);
    
        &.mounted {
          opacity: 1;
          transform: translateY(0px);
        }
        
    }

    .btn .badge {
        font-size: 10px;
        padding: 3px 5px;
    }

    .box {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 2;
        background-color: $white;
        box-shadow: 0px 5px 10px -10px rgba(0,0,0,0.4);
    }

    .img-category {
        max-width: 150px;
    }
    .search-box {

        input {
            border-radius: 0;
            padding: map-get($spacers, 2) 0;
            box-shadow: none;
            font-size: $h3-font-size;
            border-bottom: 1px solid $border-color !important;
        }

    }

}